<template>
  <Image
    v-if="hasAttachment"
    :src="src"
    :width="width"
    :height="height"
    :alt="altText"
    :srcset="srcset"
    :sizes="sizeOptions"
    @load="onLoad"
  />
  <span v-else></span>
</template>

<script>
import Image from "./Image.vue";
import { data } from "@/scripts/data.js";
import OfflineMedia from "@/scripts/offlineMedia.js";
import * as Connection from "@/scripts/connection.js";

const sizes = [
  // {
  //   name: "thumbnail",
  //   width: "thumbnail-width",
  //   height: "thumbnail-height",
  // },
  {
    name: "medium",
    width: "medium-width",
    height: "medium-height",
  },
  {
    name: "medium-large",
    width: "medium-large-width",
    height: "medium-large-height",
  },
  {
    name: "large",
    width: "large-width",
    height: "large-height",
  },
  {
    name: "1536x1536",
    width: "1536x1536-width",
    height: "1536x1536-height",
  },
  {
    name: "2048x2048",
    width: "2048x2048-width",
    height: "2048x2048-height",
  },
];

const seenSizes = [];
let first, w;

export default {
  emits: ["load"],
  components: {
    Image,
  },
  props: {
    image: {
      type: Number,
      required: true,
    },
    alt: {
      type: String,
      default: null,
    },
    sizes: {
      type: String,
      default: null,
    },
  },
  data() {
    const attachment = data.attachments[this.image];

    // do nothing if attachment data isn't set (deleted from library probably)
    if (!attachment) {
      return {
        hasAttachment: false,
      };
    }

    // use attachment data to derrive ideal image attributes
    let firstSource,
      src,
      serverSrcset,
      serverSizes,
      hasSizes,
      width,
      height,
      alt;

    ({ width, height } = attachment);

    // prefer alt text set on the component
    alt = this.alt || attachment.alt?.trim() || "";

    // srcset and sizes
    first = true;

    for (const size of sizes) {
      const file = attachment.sizes[size.name];

      if (file) {
        w = attachment.sizes[size.width];
        if (first) {
          serverSrcset = `${file} ${w}w`;
          firstSource = file;
          first = false;
          seenSizes[w] = true;
        } else if (!seenSizes[w]) {
          serverSrcset += `, ${file} ${w}w`;
          seenSizes[w] = true;
        }
      }
    }

    if (first) {
      src = attachment.url;
      hasSizes = false;
    } else if (seenSizes.length === 1) {
      src = firstSource;
      hasSizes = false;
    } else {
      src = firstSource;
      serverSizes = this.sizes || "(min-width: 720px) 85vw, 612px";
      hasSizes = serverSrcset && serverSizes;
    }

    return {
      hasAttachment: true,
      src: null,
      width,
      height,
      altText: alt,

      hasSizes,
      srcset: null,
      sizeOptions: null,

      serverSrc: src,
      serverSuccess: false,
      serverSrcset,
      serverSizes,
    };
  },
  methods: {
    onLoad() {
      this.$emit("load");
    },

    tryStorage() {
      OfflineMedia.getItem(this.image.toString()).then(
        this.storageLoaded,
        this.storageError
      );
    },
    storageLoaded(cachedImage) {
      // if the image is cached, use the cached blob
      if (cachedImage && !this.serverSuccess) {
        this.src = URL.createObjectURL(cachedImage);
      }
    },
    storageError(error) {
      console.error("Error loading image from cache:", error);
    },

    tryServer() {
      if (this.serverImg) return;

      this.serverImg = document.createElement("img");
      this.serverImg.addEventListener("load", this.serverLoaded);
      this.serverImg.addEventListener("error", this.serverError);

      if (this.hasSizes) {
        this.serverImg.srcset = this.serverSrcset;
        this.serverImg.sizes = this.serverSizes;
      }
      this.serverImg.src = this.serverSrc;
    },
    serverLoaded() {
      this.serverSuccess = true;

      if (this.hasSizes) {
        this.srcset = this.serverSrcset;
        this.sizeOptions = this.serverSizes;
      }
      this.src = this.serverSrc;

      this.raf = requestAnimationFrame(this.destroyOffscreenImg);
    },
    serverError() {
      this.destroyOffscreenImg();
      if (Connection.isOnline) {
        this.raf = requestAnimationFrame(this.tryServer);
      } else {
        Connection.addOnlineEvent(this.onOnline);
      }
    },
    destroyOffscreenImg() {
      if (this.serverImg) {
        this.serverImg.removeEventListener("load", this.serverLoaded);
        this.serverImg.removeEventListener("error", this.serverError);
        this.serverImg = null;
      }
    },

    onOnline() {
      this.tryServer();
      Connection.removeOnlineEvent(this.onOnline);
    },
  },
  created() {
    if (this.hasAttachment) {
      this.tryServer();
      if (OfflineMedia.available) {
        this.tryStorage();
      }
    }
  },
  beforeUnmount() {
    this.destroyOffscreenImg();
    cancelAnimationFrame(this.raf);
    Connection.removeOnlineEvent(this.onOnline);
  },
};
</script>
