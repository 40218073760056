<template>
  <form class="form" :class="animationClass" @submit="submit">
    <div
      class="form__wrap"
      :class="{
        'form__wrap--expand': hasValue,
      }"
    >
      <input
        v-if="hasHiddenInput"
        type="text"
        readonly
        :name="hiddenName"
        :value="hiddenValue"
        :autocomplete="hiddenType"
        :maxlength="maxlength"
        class="form__hidden"
        tabindex="-1"
      />
      <input
        ref="input"
        spellcheck="false"
        :type="type"
        :aria-label="inputLabel"
        :name="inputLabel"
        class="form__input"
        :disabled="disabled"
        :style="inputStyle"
        :class="focusClass"
        :autocomplete="autocomplete"
        :maxlength="maxlength"
        :inputmode="inputmode"
        required
      />
      <button
        type="submit"
        class="form__submit"
        :class="showSubmit"
        :disabled="submitDisabled"
        :aria-label="submitLabel"
        @click="submit"
      >
        <svg
          class="form__arrow"
          :class="{ 'form__arrow--out': disabled }"
          viewBox="0 0 17 20"
          fill="none"
          aria-hidden="true"
        >
          <path
            d="M1.5 2.20577L15 10L1.5 17.7942L1.5 2.20577Z"
            stroke="#000"
            stroke-width="3"
          />
        </svg>
      </button>
      <div
        aria-hidden="true"
        class="form__wait"
        :class="{ 'form__wait--show': waiting }"
      ></div>
      <div
        aria-hidden="true"
        class="form__done"
        :class="{ 'form__done--show': done }"
      ></div>
    </div>
  </form>
</template>

<script>
import * as AudioManager from "@/scripts/audioManager.js";
import * as Input from "@/scripts/input.js";
import { containerWidth, rem } from "@/scripts/windowSize.js";

export default {
  emits: ["submit", "next"],
  props: {
    inputLabel: {
      type: String,
      required: true,
    },
    submitLabel: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: null,
    },
    async: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "on",
    },
    maxlength: {
      type: [Number, String],
      default: null,
    },
    inputmode: {
      type: String,
      default: null,
    },
    hiddenName: {
      type: String,
      default: null,
    },
    hiddenValue: {
      type: String,
      default: null,
    },
    hiddenType: {
      type: String,
      default: null,
    },
    trim: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: "",
      disabled: false,
      waiting: false,
      done: false,
      animationClass: null,
      focusClass: null,
      inputStyle: null,
      showSubmit: null,
      wrapExpand: null,
      hasHiddenInput: this.hiddenName && this.hiddenValue,
    };
  },
  computed: {
    hasValue() {
      return this.trim ? this.value.trim().length : this.value.length;
    },
    submitDisabled() {
      return this.disabled || !this.hasValue;
    },
  },
  methods: {
    onFocus() {
      if (Input.mode === Input.KEYBOARD) {
        this.focusClass = "form__input--focus";
      }
    },
    onBlur() {
      this.focusClass = null;
    },
    onInput() {
      this.value = this.$refs.input.value;
      this.offscreen.textContent = this.value;
      this.scaleText();

      if (this.hasValue) {
        this.showSubmit = "form__submit--show";
        this.wrapExpand = "form__wrap--expand";
      } else {
        this.showSubmit = null;
        this.wrapExpand = null;
      }
    },
    next() {
      this.$emit("next", this.value);
    },
    submit(e) {
      e.preventDefault();

      this.onInput();

      const value = this.trim ? this.value.trim() : this.value;

      if (value) {
        this.$emit("submit", value);

        if (this.async) {
          this.waiting = true;
          this.disabled = true;
          this.submitTimeout = setTimeout(this.next, 600);
          AudioManager.play("ui-input-button-misc");
        } else {
          this.disabled = true;
          this.submitTimeout = setTimeout(this.next, 600);
          AudioManager.play("ui-input-next");
        }
      }
    },
    success() {
      this.waiting = false;
      this.done = true;
      AudioManager.play("ui-input-correct");
    },
    error() {
      this.animationClass = null;
      this.disabled = false;
      this.waiting = false;
      this.raf = requestAnimationFrame(this.shake);
      AudioManager.play("ui-input-wrong");
    },
    shake() {
      this.animationClass = "form--shake";
    },
    getTextWidth(size) {
      this.offscreen.style.fontSize = `${size}rem`;
      return this.offscreen.offsetWidth;
    },
    scaleText() {
      let min = this.minSize;
      let max = 2;
      let size;

      // check if max is too big
      size = max;
      if (this.getTextWidth(size) > containerWidth) {
        // check if min is too small
        size = min;
        if (this.getTextWidth(size) < containerWidth) {
          // "binary search" until size is just right
          // ~6 iterations to find size within 0.0125rem
          do {
            size = (min + max) * 0.5;
            if (this.getTextWidth(size) < containerWidth) {
              min = size;
            } else {
              max = size;
            }
          } while (0.0125 < max - min);
        }
      }

      this.inputStyle = `font-size: ${size}rem; line-height: ${size}rem; padding-top: ${
        3.5 - size
      }rem`;
    },
    resize() {
      this.minSize = Math.max(16 / rem, 1.25);
      this.scaleText();
    },
  },
  mounted() {
    // create offscreen text element
    this.offscreen = document.createElement("div");
    this.offscreen.className = "form__offscreen";
    document.body.appendChild(this.offscreen);

    // add events to input (fix weird vue bugs)
    const input = this.$refs.input;
    input.addEventListener("input", this.onInput);
    input.addEventListener("focus", this.onFocus);
    input.addEventListener("blur", this.onBlur);

    // watch resize event
    window.addEventListener("resize", this.resize);
    document.fonts.addEventListener("loadingdone", this.resize);
    this.resize();

    this.onInput();
  },
  beforeUnmount() {
    // cancel all timeouts
    clearTimeout(this.submitTimeout);
    cancelAnimationFrame(this.raf);

    // delete offscreen text element
    document.body.removeChild(this.offscreen);

    // remove inut events
    const input = this.$refs.input;
    input.removeEventListener("input", this.onInput);
    input.removeEventListener("focus", this.onFocus);
    input.removeEventListener("blur", this.onBlur);

    // remove resize event
    window.removeEventListener("resize", this.resize);
    document.fonts.removeEventListener("loadingdone", this.resize);
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/generate-gradient.scss";

@keyframes form-shake {
  @for $i from 0 through 100 {
    #{1% * $i} {
      $d: math.pow((100 - $i) / 100, 5) * 1rem;
      transform: translate((1 - 2 * random()) * $d, (1 - 2 * random()) * $d);
    }
  }
}

@keyframes form-spin {
  0% {
    transform: translateY(-50%) rotate(-360deg);
  }

  100% {
    transform: translateY(-50%) rotate(0deg);
  }
}

@keyframes form-tick-pop {
  0% {
    transform: translateY(-50%) scale(1);
  }

  20% {
    transform: translateY(-50%) scale(1.5);
  }

  100% {
    transform: translateY(-50%) scale(1);
  }
}

@keyframes form-in {
  0% {
    opacity: 0;
    transform: scale(0) translate3d(0, 0, 0);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes form-in-reduced {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.form {
  --delay: var(--page-delay);

  transform-origin: bottom right;
  animation: form-in 0.5s var(--delay) $ease-out-cubic both;

  @for $i from 2 through 10 {
    &:nth-child(#{$i}) {
      --delay: calc(var(--page-delay) + #{($i - 1) * 0.25s});
    }
  }

  @media (prefers-reduced-motion) {
    animation-name: form-in-reduced-motion;
  }

  &__wrap {
    position: relative;
    border-radius: 1.5rem 1.5rem 0 1.5rem;
    @include generate-gradient;

    background-image: url("@/assets/images/gradient.png");
    background-size: cover;

    transform-origin: bottom right;
    transform: scale(0.9);
    transition: transform 0.3s ease-out;

    @media (hover: hover) {
      &:hover {
        transform: scale(0.92);
      }
    }

    &--expand,
    &:focus-within {
      transform: scale(1) !important;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 1.125rem;
      left: 1.5rem;
      right: 1.5rem;
      height: 0.1875rem;
      background: #000;
      pointer-events: none;
    }
  }

  &--shake {
    animation: form-shake 1s linear;
    animation-delay: 0s !important;
  }

  &__offscreen {
    visibility: hidden;

    position: fixed;
    top: 0;
    left: 0;
    white-space: nowrap;

    padding: 1.5rem 4rem 1.5rem 1.5rem;

    color: #000;
    font-family: inherit;
    font-size: 2rem;
    font-weight: $bold;
    letter-spacing: inherit;
  }

  &__hidden {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }

  &__input {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 5rem;
    padding: 1.5rem 4rem 1.5rem 1.5rem;
    border: none;
    border-radius: 1.5rem 1.5rem 0 1.5rem;
    background-color: transparent;
    color: #000;
    caret-color: #000;
    font-family: inherit;
    font-size: 2rem;
    font-weight: $bold;
    letter-spacing: inherit;
    vertical-align: baseline;
    line-height: 2rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    // ios input color
    transition: color, -webkit-text-fill-color, outline-color;
    transition-duration: 0.35s;
    transition-timing-function: ease;

    @include focus-all {
      outline: none;
    }

    &:disabled {
      opacity: 1;
      color: #000;
      -webkit-text-fill-color: #000;
    }

    &--focus {
      @include focus-all {
        outline: auto;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: transparent;
      color: #000;
      font-family: inherit;
      font-size: 2rem;
      font-weight: $bold;

      // hack to prevent Chrome from forcing its autofill colors
      // delays the color change by 1000 minutes
      transition: background-color 0s 600000s, color 0s 600000s;
    }
  }

  &__submit {
    @include reset-button;

    pointer-events: none;

    position: absolute;
    top: 0;
    right: 0;
    width: 4rem;
    height: 5rem;
    border-radius: 0 1.5rem 0 0;
    font-size: 0;
    overflow: hidden;
    overflow: clip;

    opacity: 0;
    transition: opacity 0.3s ease-out;

    &--show {
      pointer-events: auto;
      opacity: 1;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    height: 1.5rem;

    &--out {
      transform: translate(100%, -50%);
      transition: transform 0.3s ease-in-out;
    }
  }

  &__wait {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    height: 1.125rem;
    width: 1.125rem;
    border-radius: 100%;
    border: solid 0.1875rem rgba(0, 0, 0, 0.25);
    border-right: solid 0.1875rem #000;
    animation: form-spin 0.5s linear infinite;

    opacity: 0;
    &--show {
      opacity: 1;
      transition: opacity 0.3s 0.2s ease-in;
    }
  }

  &__done {
    pointer-events: none;
    display: none;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    height: 1.125rem;
    width: 1.125rem;
    border-radius: 100%;
    border: solid 0.1875rem #000;
    transform: translateY(-50%);

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.25rem;
      height: 0.5rem;
      border-right: solid 0.1875rem #000;
      border-bottom: solid 0.1875rem #000;
      transform: translate(-50%, -60%) rotate(35deg);
    }

    &--show {
      display: block;
      animation: form-tick-pop 0.3s ease-out both;
    }
  }
}
</style>
