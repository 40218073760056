import countries from "@/data/locales.json";

// no-op function - probably look for a better solution that doesn't need this state
export function noop() {}

// LOCATION

// todo, properly find way around this returning "UTC"
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
if (timezone === "UTC") timezone = "Europe/London"; // greenwich, UTC
export const country = countries[timezone] || countries["Europe/London"];

// PASSIVE EVENTS

export const passiveEvent = { passive: true };
export const onceEvent = { once: true };

// FOCUS

export const focusOptions = {
  preventScroll: true,
  focusVisible: false,
};

// GRADIENT

const swipesafeGreen = "#28e231";
const swipesafeYellow = "#fbc903";
const swipesafeOrange = "#ff5c2b";
const swipesafeRed = "#fa2065";
const swipesafeMagenta = "#b901d7";
const swipesafeBlue = "#318ef3";
const swipesafeAqua = "#3cd4b9";

export const blackBackground = [
  "#000000",
  "#000000",
  "#000000",
  "#000000",
  "#000000",

  "#8148F1",
  "#5600DA",
  "#ff5c2b",
];

export const defaultGradient = [
  swipesafeMagenta,

  swipesafeOrange, // swipesafe-orange
  swipesafeAqua, // swipesafe-aqua
  swipesafeGreen, // swipesafe-green
  swipesafeYellow, // swipesafe-yellow

  "#8148F1",
  "#5600DA",
  "#FFAA38",

  // "#fa2065", // swipesafe-red
  // "#318ef3", // swipesafe-blue
  // "#3cd4b9", // swipesafe-aqua
];

// REDUCED MOTION

export let reducedMotion = true;
const reducedMotionQuery = window.matchMedia(
  `(prefers-reduced-motion: reduce)`
);
if ("addEventListener" in reducedMotionQuery) {
  reducedMotionQuery.addEventListener("change", updateReducedMotion);
} else {
  reducedMotionQuery.addListener(updateReducedMotion);
}

function updateReducedMotion() {
  reducedMotion = reducedMotionQuery.matches;
}
updateReducedMotion();
