import localForage from "localforage";

// STORAGE
// layer between data objects and localForage
// keep retrying until success
// quietly fail if storage unavailable

// separate entry objects for each key, value pair
// - retry saves if localStorage fails
// - queue saves to keys to prevent race conditions

class Entry {
  key;
  value;
  changed = false;
  running = false;
  aborted = false;
  promise;

  constructor(key, value) {
    this.key = key;
    this.value = value;
  }

  start() {
    this.store();
    this.running = true;
  }

  change(value) {
    this.value = value;

    if (this.running) {
      this.changed = true;
    }
  }

  store() {
    this.promise = localForage
      .setItem(this.key, this.value)
      .then(this.success.bind(this), this.error.bind(this));
  }

  success() {
    if (this.aborted) {
      this.finish();
    } else if (this.changed) {
      this.retry();
    } else {
      this.finish();
    }
  }

  error() {
    if (this.aborted) {
      this.finish();
    } else {
      this.retry();
    }
  }

  retry() {
    this.store();
    this.changed = false;
  }

  finish() {
    console.log("STORAGE SET", this.key, this.value);

    // remove self
    delete entries[this.key];
  }

  async abort() {
    this.aborted = true;

    // wait for the current query to finish
    (await this.promise) || Promise.resolve();

    this.finish();
  }
}

// map containing ongoing entries
const entries = {};

// Storage interface
const storage = {
  isReady: false,

  onReady() {
    console.log("storage is ready");

    this.isReady = true;

    // run all queued saves
    for (const entry in entries) {
      entry.start();
    }
  },

  ready() {
    return this.readyPromise;
  },

  // GET
  // only run on app startup
  // otherwise all data is in working memory

  async getItem(key) {
    let value;
    if (this.isReady) {
      while (true) {
        try {
          value = await localForage.getItem(key);
          console.log("STORAGE GET", key, value);
          return value;
        } catch (error) {
          console.error("Storage.getItem()", error);
        }
      }
    }
  },

  // REMOVE
  // run when PersistentData or UserData are cleared
  // completely removes the object
  // blocks until successful

  async removeItem(key) {
    if (this.isReady) {
      // cancel and wait for existing query
      const oldEntry = entries[key];
      if (oldEntry) {
        await oldEntry.abort();
      }

      // remove data from storage
      // retry forever
      while (true) {
        try {
          await localForage.removeItem(key);
          return;
        } catch (error) {
          console.error("Storage.removeItem()", error);
        }
      }
    }
  },

  // SET
  // update local storage when values change
  // retry forever until successful
  // app refresh can lose progress

  async setItem(key, value) {
    // update existing entry
    let oldEntry = entries[key];

    if (oldEntry) {
      oldEntry.change(value);
      return;
    }

    // or create a new entry
    const newEntry = new Entry(key, value);
    entries[key] = newEntry;

    // attempt storage if ready
    if (this.isReady) {
      newEntry.start();
    }
  },

  async clear() {
    const promises = [];
    for (const key in entries) {
      promises.push(entries[key].abort());
    }
    await Promise.all(promises);
    await localForage.clear();
  },
};

storage.readyPromise = localForage.ready().then(storage.onReady.bind(storage));

export default storage;

// const testNoStorage = {
//   isReady: false,

//   ready() { return Promise.reject() },
//   async getItem() {},
//   async setItem() {},
//   async clear() {},
// }

// export default testNoStorage;
