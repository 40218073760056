import PersistentData from "@/scripts/persistentData.js";
import * as Connection from "@/scripts/connection.js";

let gtmScriptTag;
let loaded = false;

export function loadGTM() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });
  loadRemoteGtmScripts();
}

function loadRemoteGtmScripts() {
  if (Connection.isOnline) {
    const firstScriptTag = document.getElementsByTagName("script")[0];

    gtmScriptTag = document.createElement("script");
    gtmScriptTag.async = true;
    gtmScriptTag.onload = onScriptLoad;
    gtmScriptTag.onerror = onScriptError;
    gtmScriptTag.src =
      "https://www.googletagmanager.com/gtm.js?id=" + process.env.VUE_APP_GTM;
    firstScriptTag.parentNode.insertBefore(gtmScriptTag, firstScriptTag);
  }
}

function onScriptLoad() {
  loaded = true;
}

function onScriptError() {
  gtmScriptTag.remove();
}

// push to data layer without an event
export function setItem(key, value) {
  window.dataLayer.push({ [key]: value });
}

let isOffloading = false;

export function trackEvent(eventName, dataLayer = {}) {
  if (!loaded) {
    loadRemoteGtmScripts();
  }
  const newEvent = {
    event: eventName,
    ...dataLayer,
  };
  try {
    if (isOffloading) {
      // wait to ensure order
      throw "wait for existing offloading";
    }
    // push and clear offline events before the new one
    if (PersistentData.gtmEvents) {
      offloadEvents();
    }
    window.dataLayer.push(newEvent);
    // clear the ones that are added after the start of this offload
    while (PersistentData.gtmEvents) {
      offloadEvents();
    }
  } catch (error) {
    console.error(error);
    // assuming it was caused by connection
    // flag the event as delayed
    newEvent.delayed = true;
    // stored in reverse order for the reduceRight loop when clearing
    PersistentData.setGtmEvents([newEvent, ...PersistentData.gtmEvents]);
  }
}

function offloadEvents() {
  // flag asap to avoid duplicated offloading
  isOffloading = true;
  const gtmEvents = PersistentData.gtmEvents;
  // clear the queue immediately in case new events need to be added
  PersistentData.setGtmEvents([]);
  // store offloading events in case client is terminated during an offload
  PersistentData.setOffloadingGtmEvents(gtmEvents);
  try {
    gtmEvents.reduceRight((acc, gtmEvent, i, arr) => {
      window.dataLayer.push(gtmEvent);
      // remove from array to handle disconnection during this loop
      arr.splice(i, 1);
      PersistentData.setOffloadingGtmEvents(arr);
    });
  } catch (error) {
    console.error(error);
    // assuming it was caused by connection
    // add offloading events back to the end of the queue
    PersistentData.setGtmEvents([
      ...PersistentData.gtmEvents,
      ...PersistentData.offloadingGtmEvents,
    ]);
    throw "offload failed";
  } finally {
    isOffloading = false;
  }
}

export let userId = undefined;

export function trackSession(cognitoUser) {
  // parse user attributes into gtm data layers
  const hasProfiles = cognitoUser.attributes["custom:hasProfiles"] === "True";
  userId = cognitoUser.attributes.sub || cognitoUser.attributes.username;
  let layers = {
    method: "AWS Cognito",
    user_id: userId,
    region: cognitoUser.attributes["custom:region"],
    group: cognitoUser.attributes["custom:group"],
    isFacilitated: cognitoUser.attributes["custom:isFacilitated"] === "True",
    isInSchool: cognitoUser.attributes["custom:isInSchool"] === "True",
    hasProfiles: hasProfiles,
  };
  if (!hasProfiles) {
    layers = {
      ...layers,
      ...{
        gender: cognitoUser.attributes.gender,
        age: cognitoUser.attributes["custom:age"],
      },
    };
  }
  trackEvent("login", layers);
}

export function destorySession() {
  userId = undefined;
  window.dataLayer.push({
    user_id: userId,
    age: undefined,
    gender: undefined,
    group: undefined,
    isFacilitated: undefined,
    isInSchool: undefined,
    hasProfiles: undefined,
  });
}

export function trackProgress(section) {
  trackEvent("page_progress", { section: section });
}

export function convertStoryPath(pathIndex) {
  return ["A", "B", "C"][pathIndex];
}

export function convertActivityScore(totalScore, activityType, activityLength) {
  switch (activityType) {
    case "HotspotActivity":
      return 100;
    case "SwipeActivity":
      return (totalScore * 100) / activityLength;
    case "MultichoiceActivity":
      return (totalScore * 100) / (activityLength * 2);
  }
}
